export const screenWidths = [
  { name: '--landscape4k', value: 3840 },
  { name: '--landscape3520', value: 3520 },
  { name: '--landscape3000', value: 3000 },
  { name: '--landscape2999', value: 2999 },
  { name: '--landscape2720', value: 2720 },
  { name: '--landscape1921', value: 1921 },
  { name: '--landscape1920', value: 1920 },
  { name: '--landscapeFHD', value: 1920 },
  { name: '--landscape1200', value: 1200 },
  { name: '--landscape1133', value: 1133 },
  { name: '--landscape1132', value: 1132 },
  { name: '--landscape801', value: 801 },
  { name: '--landscape800', value: 800 },
  { name: '--landscape768', value: 768 },
  { name: '--landscape500', value: 500 },
  { name: '--landscape480', value: 480 },
  { name: '--landscape420', value: 420 },
  { name: '--landscape384', value: 384 },
  { name: '--landscape320', value: 320 },
];
