export enum SCREENS {
  HOME = "/",
  PORTFOLIO = "/portfolio",
  ABOUT_US = "/about-us",
  JOIN_US = /*'/join-us'*/ "#",
  SERVICES = /*'/services'*/ "#",
  CONTACTS = "/contacts",
  META_MODERN_PRIVACY = "/privacy",
  TOD = "/ToD",
  TOD_PRIVACY = "/ToD/privacy",
  TOD_TERMS = "/ToD/terms",
  TIME_ZO = "/TimeZo",
  TIME_ZO_PRIVACY = "/TimeZo/privacy",
  TIME_ZO_TERMS = "/TimeZo/terms",
  NeverHaveEver = "/NeverHaveEver",
  NeverHaveEver_PRIVACY = "/NeverHaveEver/privacy",
  NeverHaveEver_TERMS = "/NeverHaveEver/terms",
  CALM_CATS = "/CalmCats",
  CALM_CATS_PRIVACY = "/CalmCats/privacy",
  CALM_CATS_TERMS = "/CalmCats/terms",
  BB_LIST = "/BB-list",
}
